import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('../views/MainPage.vue')
  }, 
  {
    path: '/mobile',
    name: 'MainPageMobile', 
    component: () => import('../views/MainPageMobile.vue')
  },
  {
    path: '/services',
    name: 'ServicesPage', 
    component: () => import('../views/ServicesPage.vue')
  }, 
  {
    path: '/work', 
    name: 'WorkPage', 
    component: () => import('../views/WorkPage.vue')
  }, 
  {
    path: '/mobile/services', 
    name: 'ServicesPageMobile', 
    component: () => import('../views/ServicesPageMobile.vue')
  }, 
  {
    path: '/mobile/work', 
    name: 'WorkPageMobile', 
    component: () => import('../views/WorkPageMobile.vue')
  },
]

const router = new VueRouter({
  mode: 'history', 
  routes
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

import forminfo from './forminfo.module'
import services from './services.module'
import workinfo from './workinfo.module'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    forminfo,
    services,
    workinfo
  }
})

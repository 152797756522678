export default {
  namespaced: true, 
  state: {
    work: [
      {
        title: 'Слесарь МСР',
        email: 'evkazan1984@mail.ru',
        cost: 'з/п 55 000 рублей',
        respons: [
          'сборка узлов и агрегатов из комплектующих',
          'слесарные работы',
          'погрузка-разгрузка деталей',
          'работа на ленточнопильном станке по металлу'
        ],
        req: [
          'образование не ниже среднего специального',
          'опыт работы от 2-х лет'
        ],
        cond: [
          'официальное трудоустройство',
          'график работы Пн-пт 07.00 до 16.00',
          'комфортные условия труда (раздевалки, душевые, комната для приема пищи и отдыха)',
          'предоставление спецодежды'
        ]
      },
      {
        title: 'Оператор станков ЧПУ',
        email: 'evkazan1984@mail.ru',
        cost: 'з/п от 70 000 рублей',
        respons: [
          'смена деталей',
          'контроль размеров',
          'чтение чертежей',
          'контроль режущего инструмента',
        ],
        req: [
          'опыт работы от 1 года (рассматриваем кандидатов без опыта работы, но с профильным образованием)',
          'образование средне специальное.'
        ],
        cond: [
          'официальное трудоустройство',
          'комфортные условия труда (раздевалки, душевые, комната для приема пищи и отдыха)',
          'предоставление спецодежды',
          'работа по графику 3/3, 12 часов (без ночных смен)'
        ]
      }, 
    ]
  }
}
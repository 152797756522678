import { formInfo } from '../_services/forminfo.service'

export default {
  namespaced: true, 

  actions: {
    saveUserFormInfo(obj, forminfo) {
      formInfo.saveFormInfo(forminfo)
    }, 
  }, 

}
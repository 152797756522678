export default {
  namespaced: true, 

  state: {
    servicesList: [
      'Охрана труда',
      'Организация и ведение кадрового делопроизводства',
      'Подбор персонала',
      'Адаптация персонала',
      'Оценка персонала',
      'Обучение и развитие персонала',
      'Разработка систем мотивации',
      'Развитие корпоративной культуры',
      'Проведение социологических исследований',
      'Консультации по трудовому законодательству',
      'Разработка документов по режиму конфиденциальности',
      // 'Коучинг и психологическое консультирование',
      // 'Система командоформирования 5F',
      'Другое'
    ]
  }
}
<template lang="pug">
  v-app.main
    router-view
</template>

<script>

export default {
  name: 'App',

  components: {

  },
  data: () => ({
    //
  }),
  methods: {
    isMobile() {
      if(screen.width <= 1190) {
        return true
      }
      else {
        return false
      }
    }
  },
  created() {
    if(this.isMobile()) {
      this.$router.push('/mobile')
    }
    else {
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
.main {
  font-family: 'Montserrat', sans-serif;
  background-color: #D7D7D7;
}
</style>
<style>
body {
  background-color: #D7D7D7;
}
</style>

import { config } from '@/_helpers'
import axios from 'axios'

axios.Axios.prototype['cacheGet'] = function(url, config = {}) {
  config.headers = { ...config.headers, 'If-None-Match': ''}
  return this.get(url, config)
}

let options = {
  baseURL: config.apiUrl,
}

export const $_app_server = axios.create(options) 
